import React from 'react';

import PageLayout from '../../components/page-layout';
import SEO from '../../components/seo';
import {
  ContentWrapper,
  ContentContainer
} from '../../components/content-layout';
import ContentBlockComponent from '../../components/content-block/content-block';

const ProjectPage = () => (
  <PageLayout path={`about`}>
    <SEO title="Project" />
    <ContentWrapper>
      <ContentContainer>
        <ContentBlockComponent
          machine_name={'about_project'}
          mainTitle={true}
        />
      </ContentContainer>
    </ContentWrapper>
  </PageLayout>
);

export default ProjectPage;
